import React, { memo } from "react";
import { valueFormat } from "src/helper/valueFormat";
import { checkDiscount } from "src/helper/checkDiscount";
import {
  convertKitchenTip,
  convertTaxAndFee,
  convertTips,
} from "src/helper/tips";
import { MESSAGE_VARIABLES } from "src/constants";

const OrderCharges = ({ orderData }) => {
  return (
    <>
      <tr className="total" style={{ fontSize: "16px" }}>
        <td>Subtotal</td>
        <td>
          {valueFormat(`$ ${Number(orderData?.charges?.subTotal).toFixed(2)}`)}
        </td>
      </tr>
      {orderData?.charges?.couponAmount || orderData?.charges?.discountAmount ? (
        <tr className="product">
          <td>
            Discount&nbsp;
            {orderData?.charges?.discountDetails?.couponTitle
              ? `(${orderData.charges.discountDetails.couponTitle})`
              : orderData?.charges?.discountDetails?.couponName
                ? `(${orderData.charges.discountDetails.couponName})`
                : orderData?.charges?.discountDetails?.discountCode
                  ? `(${orderData?.charges?.discountDetails?.discountCode})`
                  : orderData?.promotionInfo?.couponTitle ?
                    `(${orderData?.promotionInfo?.couponTitle})` :
                    orderData?.promotionInfo?.couponName ? `(${orderData?.promotionInfo?.couponName})` : ''}
          </td>
          <td style={{ fontSize: "14px" }}>
            {valueFormat(
              `($ ${Number((orderData.charges.couponAmount || 0) + (orderData?.charges?.discountAmount || 0)).toFixed(2)})`
            )}
          </td>
        </tr>
      ) : (
        <></>
      )}
      {orderData.type === "delivery" && (
        <tr className="product">
          <td>Delivery Fee</td>
          <td style={{ fontSize: "14px" }}>
            {valueFormat(
              `$ ${Number(orderData?.charges?.deliveryFee).toFixed(2)}`
            )}
          </td>
        </tr>
      )}
      {orderData?.charges?.discount && orderData?.charges?.discount > 0 ? (
        <tr className="product">
          <td>{MESSAGE_VARIABLES.cashback_used}</td>
          <td style={{ fontSize: "14px" }}>
            {checkDiscount(orderData?.charges?.discount)}
          </td>
        </tr>
      ) : (
        <></>
      )}
      <>
        <tr className="product">
          <td>Tips</td>
          <td style={{ fontSize: "14px" }}>
            {convertTips(orderData?.charges?.tipInfo, orderData)}
          </td>
        </tr>
        {orderData.charges?.tipInfo?.kitchen ||
        orderData.charges?.tipInfo?.kitchen == 0 ? (
          <tr className="tip" style={{ paddingLeft: "10px" }}>
            <td>Kitchen</td>
            <td style={{ paddingRight: "70px", fontSize: "14px" }}>
              {convertKitchenTip(orderData.charges?.tipInfo?.kitchen)}
            </td>
          </tr>
        ) : (
          ""
        )}
        {orderData.type === "delivery" && (
          <>
            {orderData.charges?.tipInfo?.driver ||
            orderData.charges?.tipInfo?.driver === 0 ? (
              <tr className="tip" style={{ paddingLeft: "10px" }}>
                <td>Driver</td>
                <td style={{ paddingRight: "70px", fontSize: "14px" }}>
                  {convertKitchenTip(orderData.charges?.tipInfo?.driver)}
                </td>
              </tr>
            ) : (
              ""
            )}
          </>
        )}
      </>
      <tr className="product">
        <td>Taxes and Fees</td>
        <td style={{ fontSize: "14px" }}>
          {convertTaxAndFee(
            orderData?.charges?.servicingFee,
            orderData?.charges?.tax
          )}
        </td>
      </tr>
      <tr className="product" style={{ paddingLeft: "10px" }}>
        <td>Tax</td>
        <td style={{ paddingRight: "70px", fontSize: "14px" }}>
          {valueFormat(`$ ${Number(orderData?.charges?.tax).toFixed(2)}`)}
        </td>
      </tr>
      {orderData?.charges?.servicingFee?.processingFee ||
      orderData?.charges?.servicingFee?.marketplaceFee ||
      orderData?.charges?.servicingFee?.ordersCoFee ? (
        <tr className="product" style={{ paddingLeft: "10px" }}>
          <td>Processing Fee</td>
          <td style={{ paddingRight: "70px", fontSize: "14px" }}>
            {valueFormat(
              `$ ${Number(
                orderData?.charges?.servicingFee?.processingFee +
                  orderData?.charges?.servicingFee?.marketplaceFee +
                  orderData?.charges?.servicingFee?.ordersCoFee
              ).toFixed(2)}`
            )}
          </td>
        </tr>
      ) : (
        ""
      )}
      <hr className="line-header" />
      <tr className="product-total">
        <td>Total</td>
        <td>
          {valueFormat(`$ ${Number(orderData?.charges?.total).toFixed(2)}`)}
        </td>
      </tr>
    </>
  );
};
export default memo(OrderCharges);
